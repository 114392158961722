import profileArticle from "images/articles/profile-article.png";

export const ARTICLES = [
  {
    name: "Optimizing Your Torc Profile: Tips from our matching team, with Federico Vera",
    content: {
      image: {
        alt: "Torc networking",
        name: "Torc networking",
        title: "Torc networking",
        filename: profileArticle,
      },
      intro:
        "Creating a Torc profile is your first step to getting matched to a position, but you'll want it to be accurate, up to date and full of the necessary info in order for us to find the right position for you. Federico Vera, a member of our matching team, gives all the advice you'll need to optimize your Torc profile and have it match-ready right here!",
      title:
        "Optimizing Your Torc Profile: Tips from our matching team, with Federico Vera",
    },
    url: "https://blog.torc.dev/optimizing-your-torc-profile-tips-from-our-matching-team-with-federico-vera",
    tag_list: ["Guide", "Developers", "Learning", "Matching"],
    first_published_at: "2025-03-07T15:00:00.000Z",
  },
];

export const EVENT_TYPES = new Map([
  ["CreateEvent", "creating new repositories "],
  ["CommitCommentEvent", "commenting on your commits"],
  ["FollowEvent", "following other users"],
  ["ForkEvent", "forking other people's code"],
  ["IssueEvent", "creating issues"],
  ["IssueCommentEvent", "commenting on issues"],
  ["PublicEvent", "open sourcing new projects"],
  ["PushEvent", "pushing lines of code"],
  ["PullRequestEvent", "submitting pull requests"],
  ["PullRequestReviewEvent", "approving pull requests"],
]);

export const SOCIAL_LINK_TYPES = {
  BADGR: "BADGR",
  CALENDAR: "CALENDAR",
  DEV: "DEV",
  FACEBOOK: "FACEBOOK",
  GITHUB: "GITHUB",
  HASHNODE: "HASHNODE",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  PORTFOLIO: "PORTFOLIO",
  STACKOVERFLOW: "STACKOVERFLOW",
  TWITTER: "TWITTER",
};

export const SOCIALLINKS = {
  TWITTER: {
    key: SOCIAL_LINK_TYPES.TWITTER,
    label: "Twitter",
  },
  STACKOVERFLOW: {
    key: SOCIAL_LINK_TYPES.STACKOVERFLOW,
    label: "Stack Overflow",
  },
  HASHNODE: {
    key: SOCIAL_LINK_TYPES.HASHNODE,
    label: "Hashnode",
  },
  PORTFOLIO: {
    key: SOCIAL_LINK_TYPES.PORTFOLIO,
    label: "Portfolio Website",
  },
  LINKEDIN: {
    key: SOCIAL_LINK_TYPES.LINKEDIN,
    label: "LinkedIn",
  },
};

export const GLOBAL_AUTH_ACTION_TYPES = {
  APP_INITIALIZED: "APP_INITIALIZED",
  USER_UPDATED: "USER_UPDATED",
  USER_SIGNEDIN: "USER_SIGNEDIN",
  USER_SIGNEDOUT: "USER_SIGNEDOUT",
  OAUTH_CUSTOM_STATE: "OAUTH_CUSTOM_STATE",
  SET_REQUIRED_ATTRIBUTES: "SET_REQUIRED_ATTRIBUTES",
};

export const USER_TYPES = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
  FREELANCER: "FREELANCER",
  TORC_RECRUITER: "TORC_RECRUITER",
  UNKNOWN: "UNKNOWN",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
};

export const OAUTH_CUSTOM_STATE_TYPES = {
  SET_USER_TYPE: "SET_USER_TYPE",
};

export const DRAFT_JOB_KEY = "create-job-draft";

export const JOB_OPPORTUNITY_STATUSES = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  DELETED: "DELETED",
  DRAFT: "DRAFT",
  FULFILLED: "FULFILLED",
  PENDINGAPPROVAL: "PENDINGAPPROVAL",
};

export const JOB_OPPORTUNITY_VISIBILITY_LEVELS = {
  LIMITED: "LIMITED",
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
};

export const JOB_OPPORTUNITY_PRIORITY_LEVELS = {
  P1: "P1",
  P2: "P2",
};

export const JOB_OPPORTUNITY_TIME_COMMITMENT = {
  FULLTIME: "FULLTIME",
  PARTTIME: "PARTTIME",
};

export const JOB_APPLICATION_MATCH_STATUS = {
  ACCEPTED: "ACCEPTED",
  APPLIED: "APPLIED",
  INTERESTED: "INTERESTED",
  INTERESTEDFASTTRACK: "INTERESTEDFASTTRACK",
  MATCHED: "MATCHED",
  PASSEDON: "PASSEDON",
  MOREINFO: "MOREINFO",
  REJECTEDBYCUSTOMER: "REJECTEDBYCUSTOMER",
  REJECTEDBYMEMBER: "REJECTEDBYMEMBER",
  REJECTEDBYMATCHER: "REJECTEDBYMATCHER",
  SHORTLISTED: "SHORTLISTED",
  PRESHORTLISTED: "PRESHORTLISTED",
  SKIPPED: "SKIPPED",
};

export const JOB_APPLICATION_MATCH_STATUS_LABEL = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  MATCHED: "Matched",
  PASSEDON: "Passed On",
  MOREINFO: "More Info",
  REJECTEDBYCUSTOMER: "Rejected",
  REJECTEDBYMEMBER: "Rejected",
  PRESHORTLISTED: "Pre-Shortlisted",
  SHORTLISTED: "Shortlisted",
  SKIPPED: "Skipped",
};

export const JOB_APPLICATION_MATCH_SUB_STATUS = {
  FINALIST: "FINALIST",
};

export const MATCH_REJECTION_REASON = {
  ENGLISHLANGDEFICIT: "ENGLISHLANGDEFICIT", // Poor verbal or written English language skills
  INADEQUATESKILLLVL: "INADEQUATESKILLLVL", // Inadequate skill level
  LACKEXPERIENCE: "LACKEXPERIENCE", // Lack of relevant experience
  LACKKNOWLEDGE: "LACKKNOWLEDGE", // Unable to clearly explain concepts or approaches
  LOCATIONNOTFEASIBLE: "LOCATIONNOTFEASIBLE", // Location or time zone overlap is not a fit
  MISSINGSKILL: "MISSINGSKILL", // Missing one or more required skills
  OTHER: "OTHER", // Other
  RATEHIGH: "RATEHIGH", // Rate is too high
};

export const MATCH_RATING_REASONS_FORMATTED = {
  RATE: "Rate",
  LOCATION: "Location",
  TIMEZONE: "Timezone",
  INSUFFICIENTSKILLS: "Insufficient Skills",
  INSUFFICIENTEXPERIENCE: "Insufficient Experience",
  EXPERIENCENOTRELEVANT: "Experience Not Relevant",
};

export const SOCIAL_LOGIN_ACCOUNTS = {
  GOOGLE: "google_",
  GITHUB: "github_",
  ETHEREUM: "SIWE_",
};

export const PROFILE_COMPLETION_LEVELS = {
  LOW: 25,
  MEDIUM: 50,
  HIGH: 75,
  FULL: 100,
};

export const PROFILE_COMPLETION_TEXT = {
  [PROFILE_COMPLETION_LEVELS.LOW]: {
    title: "Your profile is hidden to hiring managers!",
    description:
      "Your profile does not meet the minimum requirements to apply to job opportunities. Complete your profile today so you don't miss out on jobs!",
  },
  [PROFILE_COMPLETION_LEVELS.MEDIUM]: {
    title: "Your profile is getting awesome!",
    description:
      "We still need worth information to get better matches for you!",
  },
  [PROFILE_COMPLETION_LEVELS.HIGH]: {
    title: "Your profile is almost totally complete!",
    description: "Finish it to be better matched to job opportunities",
  },
  [PROFILE_COMPLETION_LEVELS.FULL]: {
    title: "Your profile is complete!",
    description:
      "That's amazing! Congratulations, don't forget to keep all data up to date to better match results",
  },
};

export const PROFILE_COMPLETION_LINKS = {
  [PROFILE_COMPLETION_LEVELS.LOW]: {
    icon: "wizardLinkLow",
    color: "#BF3A39",
  },
  [PROFILE_COMPLETION_LEVELS.MEDIUM]: {
    icon: "wizardLinkHigh",
    color: "#CD931C",
  },
  [PROFILE_COMPLETION_LEVELS.HIGH]: {
    icon: "wizardLinkHigh",
    color: "#CD931C",
  },
  [PROFILE_COMPLETION_LEVELS.FULL]: {
    icon: "wizardLinkFull",
    color: "#00924A",
  },
};

export const SIGNUP_TYPES = {
  EMAIL: 0,
  GOOGLE: 1,
  GITHUB: 2,
};

export const PROFILE_LINKS_PATH_PARAMS = {
  images: {
    wizard: 2,
    subStep: 5,
    label: "Profile Images",
  },
  skills: {
    wizard: 1,
    label: "Stacks and Skills",
  },
  connectAccounts: {
    wizard: 1,
    subStep: 4,
    label: "Connect Accounts",
  },
  generalInfo: {
    wizard: 2,
    label: "General Info",
  },
};

export const CANDIDATES_VIEW_MODES = {
  GRIDVIEW: "GRIDVIEW",
  LISTVIEW: "LISTVIEW",
};

export const JOB_APPLICATION_MATCH_RATINGS = {
  VERYUNDESIRABLE: "VERYUNDESIRABLE",
  UNDESIRABLE: "UNDESIRABLE",
  NEUTRAL: "NEUTRAL",
  DESIRABLE: "DESIRABLE",
  VERYDESIRABLE: "VERYDESIRABLE",
};

export const JOB_APPLICATION_MATCH_RATINGS_FORMATTED = {
  VERYUNDESIRABLE: "Very Undesirable",
  UNDESIRABLE: "Undesirable",
  NEUTRAL: "Neutral",
  DESIRABLE: "Desirable",
  VERYDESIRABLE: "Very Desirable",
};

export const REFERRAL_TYPES = {
  JOB: "JOB",
  PUBLIC_JOB: "PUBLIC_JOB",
  USER: "USER",
};

export const NOTE_TYPES = {
  CALIBRATION: "CALIBRATION",
  GENERAL: "GENERAL",
  JOBFEEDBACK: "JOBFEEDBACK",
};

export const RATE_TYPES = {
  CALIBRATION: "calibrationRate",
  CUSTOMER: "customerRate",
};

export const CALIBRATION_VIRTUAL_STATUS = {
  ACTIVE: "ACTIVE",
  COMPLETE: "COMPLETE",
  NOTYETSTARTED: "NOTYETSTARTED",
};

export const GEO_REGION_TYPE = {
  BY_REGION: 1,
  BY_COUNTRY: 2,
  BY_CITY: 3,
};

export const SALARY_CONFIG = {
  MAX_RATE_PER_HOUR: 200,
  MAX_SALARY: 416000,
};

export const EMPLOYMENT_TYPES = {
  CONTRACT: "Contract",
  DTCPERMPLACEMENT: "DTC Permanent Placement",
  DTCSOLUTION: "DTC Solutions Position",
  PERMPLACEMENT: "Permanent Placement",
  CONTRACTTOPERM: "Contract to Permanent Placement",
  SOLUTION: "Solutions Position",
  IC: "Independent Contractor (IC)",
};

/*
  CURRENCY_CONFIG: {
    url: third party conversion api url,
    apiKey: third party conversion api key,

    exchangeFactor: 0.0117729 // number INR (Indian Rupee) factor, (if its not defined and url its defined API currency conversion currency will be enabled)
    locationCountryName: "India" // the country name for india that will work with  exchangeFactor
  }
  */
export const CURRENCY_CONFIG = JSON.parse(
  process.env.REACT_APP_CURRENCY_CONVERSION || "{}"
);
export const YEARS_OF_EXPERIENCE_CONFIG = JSON.parse(
  process.env.REACT_APP_YEARS_OF_EXPERIENCE_CONFIG || "{}"
);

export const USER_NEEDS_ACTION_STATUS = {
  NEEDS_ACTION: "NEEDS_ACTION",
  NO_ACTION_NEEDED: "NO_ACTION_NEEDED",
  USER_NOT_FOUND: "USER_NOT_FOUND",
};
