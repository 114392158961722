import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useDebounce } from "usehooks-ts";
import { useAuth } from "GlobalAuthContext";
import moment from "moment";
import classNames from "classnames";
import { cloneDeep } from "lodash";

import { yupResolver } from "@hookform/resolvers/yup";
import { API, graphqlOperation, Auth } from "aws-amplify";

import Footer from "components/Footer";
import Header from "components/Header";
import Select from "components/FormComponents/Select";
import Radio from "components/FormComponents/Radio";
import Input from "components/FormComponents/Input";
import Button from "components/FormComponents/Button";
import SkillSearch from "components/FormComponents/SkillSearch";
import InfoPopover from "components/FormComponents/InfoPopover";
import TimezoneSelect from "components/FormComponents/TimezoneSelect";
import Textarea from "components/FormComponentsNew/Textarea";
import {
  formGetJobOpportunity as getJobOpportunity,
  listCompanyUsers,
  listJobTypes,
  generateJobShortDescription,
} from "graphql/queries";
import { createJobOpportunity, updateJobOpportunity } from "graphql/mutations";
import FormError from "components/FormComponents/FormError";
import RegionSearch from "components/FormComponents/RegionSearch";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownEditor from "components/FormComponents/MarkdownEditor";
import ConfirmationModal from "./ConfirmationModal";
import JobPageWrapper from "pages/Jobs/Opportunities/JobPageWrapper";
import {
  DRAFT_JOB_KEY,
  GEO_REGION_TYPE,
  JOB_OPPORTUNITY_PRIORITY_LEVELS,
  JOB_OPPORTUNITY_STATUSES,
  EMPLOYMENT_TYPES,
  CURRENCY_CONFIG,
  YEARS_OF_EXPERIENCE_CONFIG,
} from "lookup";
import rightArrow from "images/right-arrow.png";
import { schema } from "./schema";
import { purifyMarkDown } from "utils/markdown";
import GeographicalRegionSearch from "components/FormComponents/GeographicalRegionSearch";
import SearchLocations from "components/Locations";
import {
  formatLocation,
  formatJobStatus,
  unformatLocation,
} from "helpers/utils";
import {
  baseToTargetCurrency,
  getApiCurrencies,
  getCurrencyApiClient,
  getCurrencyMappedOptions,
  getInitialCurrencies,
  getINRDefaultCurrency,
} from "helpers/currency";

import CurrencySelect from "./molecules/CurrencySelect";
import SvgIcon from "components/SvgIcon";

const RATE_CONFIG = {
  min: 1,
  max: 300,
  defaultMin: 45,
  defaultMax: 150,
};

const SALARY_CONFIG = {
  min: 1,
  max: 416000,
  defaultMin: 40000,
  defaultMax: 150000,
};

const CURRENCY = "USD";

const SHORT_DESCRIPTION_VALIDATION_FIELDS = [
  "jobTypeId",
  "title",
  "overview",
  "skills",
  "responsibilities",
  "requirements",
  "regions",
  "geographicalRegions",
  "location",
];

const YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED =
  YEARS_OF_EXPERIENCE_CONFIG.locationCountryNames?.length > 0;

const isCountryAllowedForYearsOfExperience = (countryName) => {
  if (!YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED) {
    return false;
  }

  return YEARS_OF_EXPERIENCE_CONFIG.locationCountryNames.includes(countryName);
};

const locations = (() => {
  const originalLocations = JSON.parse(
    process.env.REACT_APP_GEOGRAPHICAL_REGIONS
  );
  const bannedCountries = JSON.parse(process.env.REACT_APP_BANNED_COUNTRIES);

  return originalLocations.map((region) => {
    const filteredCountries = region.countryNames.filter(
      (country) => !bannedCountries.includes(country)
    );

    return {
      ...region,
      countryNames: filteredCountries,
    };
  });
})();

function getCountryValue(regionName, countryName) {
  return `${regionName}__${countryName}`;
}

function getFormattedGeographicalRegions(unformattedGeoRegions) {
  const gr = {};

  unformattedGeoRegions.forEach((r) => {
    const [regionName, countryName] = r.value.split("__");

    if (gr[regionName]) {
      gr[regionName].push(countryName);
    } else {
      gr[regionName] = [countryName];
    }
  });

  let formattedGeoRegions = Object.keys(gr).map((k) => ({
    regionName: k,
    countryNames: gr[k],
  }));

  formattedGeoRegions.forEach((gr) => {
    const geoRegion = locations.find((o) => o.regionName === gr.regionName);

    if (geoRegion) {
      if (geoRegion.countryNames.length === gr.countryNames.length) {
        // All countries in the region - no need to specify them individually
        gr.countryNames = [];
      }
    }
  });

  return [formattedGeoRegions, gr];
}

function getGeoRegionType(jobOpp) {
  if (jobOpp.location?.locationId) {
    return GEO_REGION_TYPE.BY_CITY;
  }

  for (let i = 0; i < jobOpp.geographicalRegions?.length; i++) {
    const region = jobOpp.geographicalRegions[i];

    if (region.countryNames.length > 0) {
      return GEO_REGION_TYPE.BY_COUNTRY;
    }
  }

  return GEO_REGION_TYPE.BY_REGION;
}

const rateInstructions = {
  default: {
    hourlyRate: `Use the fields below to select the minimum and maximum
hourly rates for this job. This is used to screen developers
who have set their rates above or below your desired range.
We recommend setting a wider range so as not to screen out
qualified developers. This is especially important if you're
unsure of the current market rate for your job.`,
    yearlyRate: `Use the fields below to select the minimum and maximum
yearly salary for this job. This is used to screen
developers who have set their salaries above or below your
desired range. We recommend setting a wider range so as not
to screen out qualified developers. This is especially
important if you're unsure of the current market rate for
your job.`,
  },
  conversion: {
    hourlyRate: `Use the fields below to select the minimum and maximum
hourly rates for this job. This is used to screen developers
who have set their rates above or below your desired range.
We recommend setting a wider range so as not to screen out
qualified developers. This is especially important if you're
unsure of the current market rate for your job.`,
    yearlyRate: `Use the fields below to select the minimum and maximum
yearly salary for this job. This is used to screen
developers who have set their salaries above or below your
desired range. We recommend setting a wider range so as not
to screen out qualified developers. This is especially
important if you're unsure of the current market rate for
your job.`,
  },
};

export default function CreateEditJobOpps() {
  const history = useHistory();
  const params = useParams();
  const { user } = useAuth();

  const isCancelled = useRef();

  const companyINRCurrencyConversionEnabled =
    user.company && CURRENCY_CONFIG.companyNames?.includes(user.company);

  const currencyConversionIsEnabled =
    (companyINRCurrencyConversionEnabled && CURRENCY_CONFIG.exchangeFactor) ||
    (!CURRENCY_CONFIG.exchangeFactor && CURRENCY_CONFIG.url);

  const defaultCurrency =
    companyINRCurrencyConversionEnabled && CURRENCY_CONFIG.exchangeFactor
      ? getINRDefaultCurrency(CURRENCY_CONFIG)
      : {};

  const currencyConfigRef = useRef({
    currencies:
      companyINRCurrencyConversionEnabled && CURRENCY_CONFIG.exchangeFactor
        ? getInitialCurrencies(CURRENCY_CONFIG.exchangeFactor)
        : {},
    currentCurrency: {
      value: "USD",
      label: "USD (US Dollar)",
      factor: 1,
      ...defaultCurrency,
    },
  });

  const currencyFactor = currencyConfigRef.current.currentCurrency.factor;

  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);

  const [initializingForm, setInitializingForm] = useState(true);
  const [formData, setFormData] = useState({
    employmentType: "",
    organization: "",
    overview: "",
    responsibilities: `* bullet one\n* bullet two\n* bullet three\n`,
    requirements: `* bullet one\n* bullet two\n* bullet three\n`,
    timezone: { label: "", value: "" },
    minRate: RATE_CONFIG.defaultMin,
    maxRate: RATE_CONFIG.defaultMax,
    minSalary: SALARY_CONFIG.defaultMin,
    maxSalary: SALARY_CONFIG.defaultMax,
    requiredPositions: 1,
    jobLengthInWeeks: 12,
    customerOwnerId: "",
    primaryMatcherId: "",
    externalJobId: "",
    regions: [],
    geographicalRegions: [],
    shortDescription: "",
    requiredExperience: 0,
  });
  const [jobTypes, setJobTypes] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [confirmingAction, setConfirmingAction] = useState(false);
  const [saveAsPreview, setSaveAsPreview] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [fetchedJobTypes, setFetchedJobTypes] = useState(false);
  const [cognitoGroups, setCognitoGroups] = useState([]);
  const [requiredPositions, setRequiredPositions] = useState(
    formData.requiredPositions || 1
  );
  const [jobLengthInWeeks, setJobLengthInWeeks] = useState(
    formData.jobLengthInWeeks
  );
  const [showRequiredExperience, setShowRequiredExperience] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [shortDescriptionObj, setShortDescriptionObj] = useState({ words: 0 });
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const options = ["LIMITED", "PUBLIC", "PRIVATE"];
  const [visibility, setVisibility] = useState("");
  const [activeGeoRegionType, setActiveGeoRegionType] = useState(
    GEO_REGION_TYPE.BY_REGION
  );
  const [compensationType, setCompensationType] = useState("ratePerHour");

  const overviewRef = useRef();
  const responsibilitiesRef = useRef();
  const requirementsRef = useRef();
  const skillsRef = useRef();
  const timeZoneRef = useRef();
  const employmentTypeRef = useRef();

  const realUSDRate = useRef({});

  const realUSDSalary = useRef({});

  // Erase any previous data stored about job opps since that is no longer being used
  // Today is 30th April 2024. If you are still seeing this in August 2024, you can safely remove this statement
  // Assuming that is sufficient time to clear up local storage of all users that worked with this feature in the past
  localStorage.removeItem(DRAFT_JOB_KEY);

  const isAdmin = useCallback(() => {
    return (
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP) ||
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_GROUP_JOB_MANAGERS)
    );
  }, [cognitoGroups]);

  const isManager = useCallback(() => {
    return (
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP) ||
      cognitoGroups.includes(
        process.env.REACT_APP_COGNITO_GROUP_JOB_MANAGERS
      ) ||
      cognitoGroups.includes(process.env.REACT_APP_COGNITO_GROUP_USER_MANAGERS)
    );
  }, [cognitoGroups]);

  const isPremiumCustomer = useMemo(
    () =>
      cognitoGroups.includes(
        process.env.REACT_APP_COGNITO_GROUP_PREMIUM_CUSTOMERS
      ),
    [cognitoGroups]
  );

  const canAccessJobVisibility = useCallback(() => {
    const accessToJobVisibility = JSON.parse(
      process.env.REACT_APP_ALLOWED_GROUPS_JOB_VISIBILITY_ACCESS || "[]"
    );
    return accessToJobVisibility.some((g) => cognitoGroups.includes(g));
  }, [cognitoGroups]);

  const handleChange = (event) => {
    setVisibility(event.target.value);
  };

  const fetchExistingJobOpp = async (id, groups) => {
    const isPremium = groups.includes(
      process.env.REACT_APP_COGNITO_GROUP_PREMIUM_CUSTOMERS
    );

    try {
      const res = await API.graphql(
        graphqlOperation(getJobOpportunity("", isPremium, false), { id })
      );

      if (!res?.data?.getJobOpportunity) history.push("/404");

      setActiveGeoRegionType(getGeoRegionType(res.data.getJobOpportunity));

      const existingJobOpp = postFetchFormat(res.data.getJobOpportunity);

      if (!isCancelled.current) {
        setFormData({ ...existingJobOpp });
        setInitializingForm(false);
        setCompensationType(existingJobOpp.minRate ? "ratePerHour" : "salary");
      }
    } catch (err) {
      console.log("Error when fetching existing job opportunity");
      console.log(err);
    }
  };

  useEffect(() => {
    if (compensationType === "ratePerHour") {
      let minRate = formData.minRate || RATE_CONFIG.min;
      let maxRate = formData.maxRate || RATE_CONFIG.max;

      if (currencyConfigRef.current.currentCurrency.value !== "USD") {
        minRate = baseToTargetCurrency(minRate, currencyFactor);
        maxRate = baseToTargetCurrency(maxRate, currencyFactor);
      }

      setTimeout(() => {
        setValue("minRate", minRate);
        setValue("maxRate", maxRate);
      }, 5);
      clearErrors(["minRate", "maxRate"]);
    } else {
      let minSalary = formData.minSalary || SALARY_CONFIG.min;
      let maxSalary = formData.maxSalary || SALARY_CONFIG.max;

      if (currencyConfigRef.current.currentCurrency.value !== "USD") {
        minSalary = baseToTargetCurrency(minSalary, currencyFactor);
        maxSalary = baseToTargetCurrency(maxSalary, currencyFactor);
      }

      setTimeout(() => {
        setValue("minSalary", minSalary);
        setValue("maxSalary", maxSalary);
      }, 5);
      clearErrors(["minSalary", "maxSalary"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compensationType,
    formData.minRate,
    formData.maxRate,
    formData.minSalary,
    formData.maxSalary,
  ]);

  const methods = useForm({
    defaultValues: formData,
    resolver: yupResolver(
      schema(
        RATE_CONFIG,
        SALARY_CONFIG,
        isAdmin(),
        {
          compensationType,
          employmentType: employmentTypeRef.current,
        },
        currencyConfigRef.current.currentCurrency,
        showRequiredExperience
      )
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    trigger,
    watch,
    resetField,
    clearErrors,
    unregister,
    formState: { errors, dirtyFields },
  } = methods;

  const watchMinRate = watch("minRate");
  const watchMaxRate = watch("maxRate");

  const watchMinSalary = watch("minSalary");
  const watchMaxSalary = watch("maxSalary");

  // Not using isDirty because it may not always show the right value
  // See - https://github.com/react-hook-form/react-hook-form/issues/3213#issuecomment-762320328
  // (and the discussion further in that ticket)
  // dirtyFields is a better indicator
  const isDirtyAlt = !!Object.keys(dirtyFields).length;
  const watchOrganization = watch("organization");
  const debounceOrganization = useDebounce(watchOrganization, 1000);

  const checkFormJobHasSkills = (data) => {
    if (data.skills?.length > 0 || data.optionalSkills?.length > 0) {
      return true;
    }

    setError("skillsAreRequired", {
      type: "custom",
      message: "Please select at least one required or optional skill",
    });

    return false;
  };

  const save = async (_data) => {
    const data = { ..._data };

    if (!checkFormJobHasSkills(data)) {
      skillsRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (data.overview) {
      data.overview = purifyMarkDown(data.overview);
    }

    if (data.responsibilities) {
      data.responsibilities = purifyMarkDown(data.responsibilities);
    }

    if (data.requirements) {
      data.requirements = purifyMarkDown(data.requirements);
    }

    if (compensationType === "ratePerHour") {
      data.minRate = {
        currency: CURRENCY,
        value:
          realUSDRate.current.min !== undefined
            ? parseInt(realUSDRate.current.min)
            : data?.minRate,
      };
      data.maxRate = {
        currency: CURRENCY,
        value:
          realUSDRate.current.max !== undefined
            ? parseInt(realUSDRate.current.max)
            : data?.maxRate,
      };
      delete data.minSalary;
      delete data.maxSalary;
    } else if (compensationType === "salary") {
      data.minSalary = {
        currency: CURRENCY,
        value:
          realUSDSalary.current.min !== undefined
            ? parseInt(realUSDSalary.current.min)
            : data?.minSalary,
      };
      data.maxSalary = {
        currency: CURRENCY,
        value:
          realUSDSalary.current.max !== undefined
            ? parseInt(realUSDSalary.current.max)
            : data?.maxSalary,
      };
      delete data.minRate;
      delete data.maxRate;
    }

    delete data.isAdmin;

    if (data.requiredExperience && data.requiredExperience > 0) {
      if (data.location?.id) {
        // Field is allowed if location is whitelisted
        if (!isCountryAllowedForYearsOfExperience(data.location.country_name)) {
          data.requiredExperience = null;
        }
      } else if (data.geographicalRegions) {
        // Filed is only allowed if ALL locations in geo region are whitelisted
        if (
          !data.geographicalRegions.every((gr) =>
            isCountryAllowedForYearsOfExperience(gr.label)
          )
        ) {
          data.requiredExperience = null;
        }
      } else {
        data.requiredExperience = null;
      }
    }

    if (data.id && data.regions?.length > 0) {
      // Backward compatibility - if regions already existed on the job
      // process it. We check for id, only because new job creation
      // (where id won't exist yet) will NOT use regions field
      data.regions = data.regions?.map((region) => region.name);
    } else if (data.location?.id) {
      // Either region or location can be set - not both
      data.geographicalRegions = null;
      data.regions = null;

      data.location = formatLocation({ ...data.location });
    } else if (data.geographicalRegions) {
      data.geographicalRegions = getFormattedGeographicalRegions(
        data.geographicalRegions
      )[0];

      // Overwrite regions to ensure that only geographical regions exist
      // Not really needed, since it is not showing in the UI itself, but a precaution just in case
      data.regions = null;

      // Either region or location can be set - not both
      data.location = null;
    }

    data.startDate = data.startDate
      ? moment(data.startDate).format(`YYYY-MM-DD`)
      : null;

    // Ensure that we don't pass empty string
    data.timeOverlap = data.timeOverlap || null;

    // If there's no status set, set it to DRAFT
    if (!formData.status) {
      data.status = JOB_OPPORTUNITY_STATUSES.DRAFT;
    }

    // If there's no visibility set, set it to LIMITED
    if (!data.visibilityLevel) {
      data.visibilityLevel = "LIMITED";
    }

    data.organization = data.organization.trim();

    if (data.priorityLevel !== undefined && !data.priorityLevel) {
      data.priorityLevel = null;
    }

    if (!data.shortDescription?.trim()) {
      setIsGeneratingDescription(true);
      const generatedShortDescription = await generateDescription();
      setIsGeneratingDescription(false);

      if (!generatedShortDescription || !generatedShortDescription.length) {
        return;
      }

      data.shortDescription = generatedShortDescription.trim();
    } else data.shortDescription = data.shortDescription.trim();

    data.customerOwner = companyUsers?.find(
      (c) => c.id === data.customerOwnerId
    );

    delete data.customerOwnerId;

    if (data.customerOwner) {
      delete data.customerOwner.canBePrimaryMatcher;
    }

    data.externalJobId = data.externalJobId?.trim();

    if (!data.externalJobId) {
      delete data.externalJobId;
    }

    data.torcOwner = companyUsers?.find((c) => c.id === data.primaryMatcherId);

    delete data.primaryMatcherId;

    if (data.torcOwner) {
      delete data.torcOwner.canBePrimaryMatcher;
    }

    if (data.employmentType === "") {
      data.employmentType = null;
    }

    let job;

    try {
      if (data.id) {
        job = await API.graphql(
          graphqlOperation(updateJobOpportunity, { input: data })
        );

        return job.data.updateJobOpportunity.id;
      } else {
        job = await API.graphql(
          graphqlOperation(createJobOpportunity, { input: data })
        );

        return job.data.createJobOpportunity.id;
      }
    } catch (err) {
      const message = err.errors?.[0]?.message || "";
      if (
        message.includes("Primary matcher user should be sales rep or admin") ||
        message.includes("Provided Assign torcOwner unauthorized")
      ) {
        setError(
          "primaryMatcherId",
          {
            type: "custom",
            message:
              "The selected matcher is not allowed to be set as primary.",
          },
          { shouldFocus: true }
        );
      }
    }
  };

  const handleOnErrors = () => {
    (errors?.overview?.message ||
      errors?.responsibilities?.message ||
      errors?.requirements?.message ||
      errors?.skillsAreRequired?.message ||
      errors?.timezone?.message) &&
      setTimeout(() => {
        scrollTextAreaIntoViewOnError();
      }, 5);
  };

  const onPreview = async (_data) => {
    setSaveAsPreview(true);
    const jobId = await save(_data);
    if (!jobId) {
      setSaveAsPreview(false);
      return;
    }
    let previewUrl = `/jobs/opportunities/${jobId}/preview`;
    if (!formData.id) {
      previewUrl += "?origin=create";
    }

    history.push(previewUrl);
  };

  const onDraft = async (_data) => {
    const data = { ..._data };
    setSaveAsDraft(true);
    const jobId = await save(data);

    if (!jobId) {
      setSaveAsDraft(false);
      return;
    }

    history.push(`/jobs/opportunities/${jobId}/saved`);
  };

  const saveDraft = async () => {
    const result = await trigger("jobTypeId", { shouldFocus: true });
    const organizationResult = await trigger("organization", {
      shouldFocus: true,
    });

    if (result && organizationResult) {
      await onDraft({
        ...getValues(),
      });
    }
  };

  const delJobOpp = async () => {
    if (formData.id) {
      await API.graphql(
        graphqlOperation(updateJobOpportunity, {
          input: { id: formData.id, status: JOB_OPPORTUNITY_STATUSES.DELETED },
        })
      );
    }
    setConfirmingAction(false);

    if (formData.id) {
      history.push(`/jobs/opportunities/${formData.id}/deleted`);
    } else {
      history.push(`/jobs/opportunities/deleted`);
    }
  };

  const cancelJobOpp = async () => {
    await API.graphql(
      graphqlOperation(updateJobOpportunity, {
        input: { id: formData.id, status: JOB_OPPORTUNITY_STATUSES.CANCELLED },
      })
    );
    setConfirmingAction(false);

    history.push(`/jobs/opportunities/${formData.id}/confirmation`);
  };

  const restoreJobOpp = async () => {
    await API.graphql(
      graphqlOperation(updateJobOpportunity, {
        input: {
          id: formData.id,
          status: JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL,
        },
      })
    );
    setConfirmingAction(false);

    history.push(`/jobs/opportunities/${formData.id}/confirmation`);
  };

  const onConfirmAction = async () => {
    setConfirmingAction(true);

    switch (formData.status) {
      case JOB_OPPORTUNITY_STATUSES.ACTIVE:
        return cancelJobOpp();
      case JOB_OPPORTUNITY_STATUSES.CANCELLED:
        return restoreJobOpp();
      default:
        return delJobOpp();
    }
  };

  // Format the form data after fetching it
  // for some input fields (like region) to handle it better
  // Ex. Region field uses useFieldArray hook, which requires the
  // data to be in { id, name } format, but our db only stores it
  // as a string. This function converts it to the format that
  // useFieldArray expects it to be in
  const postFetchFormat = (jobData) => {
    if (!jobData?.regions) jobData.regions = [];
    if (jobData?.regions?.length > 0) {
      jobData.regions = jobData?.regions?.map((r) => ({ id: r, name: r }));
    }

    if (jobData.geographicalRegions) {
      jobData.geographicalRegions = jobData.geographicalRegions
        .map((l) => {
          if (l.countryNames.length === 0) {
            // Consider all countries
            const region = locations.find((k) => k.regionName === l.regionName);

            return region.countryNames.map((c) => ({
              label: c,
              value: getCountryValue(region.regionName, c),
            }));
          }
          return l.countryNames.map((c) => ({
            label: c,
            value: getCountryValue(l.regionName, c),
          }));
        })
        .flat();
    } else {
      jobData.geographicalRegions = [];
    }

    if (!jobData.location) {
      jobData.location = {};
    } else {
      jobData.location = unformatLocation({ ...jobData.location });
    }

    if (!jobData.skills) {
      jobData.skills = [];
    }

    if (!jobData.optionalSkills) {
      jobData.optionalSkills = [];
    }

    if (!jobData.jobLengthInWeeks && !!jobData.jobLength) {
      jobData.jobLengthInWeeks = jobData.jobLength * 4;
    }

    const {
      id,
      customerOwner,
      externalJobId,
      torcOwner,
      geographicalRegions,
      jobLengthInWeeks,
      jobTypeId,
      location,
      organization,
      overview,
      priorityLevel,
      minRate,
      maxRate,
      minSalary,
      maxSalary,
      optionalSkills,
      regions,
      requirements,
      requiredExperience,
      requiredPositions,
      responsibilities,
      shortDescription,
      skills,
      startDate,
      status,
      timeCommitment,
      timeOverlap,
      timezone,
      title,
      visibilityLevel,
      workMode,
    } = jobData;

    return {
      id,
      customerOwnerId: customerOwner?.id,
      externalJobId,
      employmentType: jobData.employmentType,
      primaryMatcherId: torcOwner?.id,
      geographicalRegions,
      jobLengthInWeeks,
      jobTypeId,
      location,
      organization,
      overview,
      priorityLevel,
      minRate: minRate?.value,
      maxRate: maxRate?.value,
      minSalary: minSalary?.value,
      maxSalary: maxSalary?.value,
      optionalSkills,
      regions,
      requirements,
      requiredExperience,
      requiredPositions,
      responsibilities,
      shortDescription,
      skills,
      startDate,
      status,
      timeCommitment,
      timeOverlap,
      timezone: timezone || { label: "", value: "" },
      title,
      visibilityLevel,
      workMode,
    };
  };

  const viewJobs = () => {
    history.push("/jobs/opportunities");
  };

  const fetchJobOpp = async (p, groups) => {
    if (p?.id) {
      await fetchExistingJobOpp(p.id, groups);
    } else if (!isCancelled.current) {
      setInitializingForm(false);
    }
  };

  useEffect(() => {
    if (
      currencyConversionIsEnabled &&
      !CURRENCY_CONFIG.exchangeFactor &&
      CURRENCY_CONFIG.url
    ) {
      (async () => {
        setIsLoadingCurrencies(true);
        currencyConfigRef.current.currencies = await getApiCurrencies();
        setIsLoadingCurrencies(false);
      })();
    }
  }, [currencyConversionIsEnabled]);

  const watchShortDescription = watch("shortDescription");

  useEffect(() => {
    const subscription = watch((value) => {
      setShortDescriptionObj((prev) => ({
        ...prev,
        words: value.shortDescription?.trim()
          ? getValues("shortDescription").trim().split(/\s+/).length
          : 0,
      }));
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchShortDescription]);

  const watchEmploymentType = watch("employmentType");

  useEffect(() => {
    employmentTypeRef.current = watchEmploymentType;

    if (
      watchEmploymentType === "PERMPLACEMENT" &&
      getValues().jobLengthInWeeks
    ) {
      setValue("jobLengthInWeeks", null);
    } else if (!getValues().jobLengthInWeeks) {
      setValue("jobLengthInWeeks", 12);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmploymentType]);

  const watchGeographicalRegions = watch("geographicalRegions");
  const watchLocation = watch("location");

  useEffect(() => {
    if (!YEARS_OF_EXPERIENCE_CONFIG_IS_ENABLED) {
      return;
    }

    let isLocationOfConcern = false;

    if (
      watchGeographicalRegions?.length > 0 &&
      watchGeographicalRegions?.every((gr) =>
        isCountryAllowedForYearsOfExperience(gr.label)
      )
    ) {
      isLocationOfConcern = true;
    } else if (
      isCountryAllowedForYearsOfExperience(watchLocation?.country_name)
    ) {
      isLocationOfConcern = true;
    }

    if (isLocationOfConcern) {
      setShowRequiredExperience(true);
    } else {
      setShowRequiredExperience(false);
    }
  }, [register, unregister, watchGeographicalRegions, watchLocation]);

  useEffect(() => {
    if (cognitoGroups.length > 0 && !isAdmin()) {
      (async () => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(listCompanyUsers, {
              company: user.company,
              filter: { flagPrimaryMatchers: true },
            })
          );

          if (data?.listCompanyUsers?.items?.length > 0) {
            setCompanyUsers(data.listCompanyUsers.items);
          } else {
            setCompanyUsers([
              {
                id: user.id,
                username: user.username,
              },
            ]);
          }

          if (!params.id && !getValues("customerOwnerId")) {
            setValue("customerOwnerId", user?.id);
          }
        } catch (error) {
          console.error("Error fetching users by company:", error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, user, cognitoGroups, resetField]);

  const handleRateChange = (e, valueKey) => {
    const { onChange } = register(valueKey);

    const value = +e.target.value || 0;
    const oppositeKey = valueKey === "minRate" ? "maxRate" : "minRate";
    const oppositeValue = +getValues(oppositeKey);

    const isOppositeValueValid = (() => {
      let isValid = true;

      if (!oppositeValue) {
        isValid = false;
      }

      if (valueKey === "minRate" && value > oppositeValue) {
        isValid = false;
      }

      if (valueKey === "maxRate" && value < oppositeValue) {
        isValid = false;
      }

      return isValid;
    })();

    if (!isOppositeValueValid) {
      setValue(oppositeKey, value);
    }

    setValue(valueKey, value);
    onChange({ ...e, target: { ...e.target, value } });
  };

  const handleSalaryChange = (e, valueKey) => {
    const { onChange } = register(valueKey);

    const value = +e.target.value || 0;
    const oppositeKey = valueKey === "minSalary" ? "maxSalary" : "minSalary";
    const oppositeValue = +getValues(oppositeKey);

    const isOppositeValueValid = (() => {
      let isValid = true;

      if (!oppositeValue) {
        isValid = false;
      }

      if (valueKey === "minSalary" && value > oppositeValue) {
        isValid = false;
      }

      if (valueKey === "maxSalary" && value < oppositeValue) {
        isValid = false;
      }

      return isValid;
    })();

    if (!isOppositeValueValid) {
      setValue(oppositeKey, value);
    }

    setValue(valueKey, value);
    onChange({ ...e, target: { ...e.target, value } });
  };

  const handleNumberFieldChange = (e, valueKey) => {
    const { onChange } = register(valueKey);

    let value;

    if (valueKey === "requiredExperience") {
      // This field is optional - and thus, default value will be null
      if (e.target.value?.length > 0) {
        // In this case, user seems to have entered a non numeric value
        // => We set 0 and not null to trigger validation error (min value is 1)
        value = +e.target.value || 0;
      } else {
        value = null;
      }
    } else {
      value = +e.target.value || 0;
    }

    setValue(valueKey, value);
    onChange({ ...e, target: { ...e.target, value } });
  };

  // Determine if we are editing a job opportunity or creating a new one
  useEffect(() => {
    isCancelled.current = false;
    let isMounted = true;
    let groups;
    (async () => {
      try {
        const auth = await Auth.currentSession();

        groups = auth.getAccessToken().payload["cognito:groups"] || [];
        setCognitoGroups(groups);
      } catch (err) {
        console.log("Error getting current session", err);
      }
      if (fetchedJobTypes) {
        // Job titles were fetched - just fetch the job opp

        await fetchJobOpp(params, groups);
      } else {
        // First fetch job titles

        const res = await API.graphql(
          graphqlOperation(listJobTypes, { filter: { isActive: { eq: true } } })
        );

        if (isMounted) {
          setFetchedJobTypes(true);
          setJobTypes(res.data.listJobTypes.items);
        }

        // Next, proceed to fetch job opp
        await fetchJobOpp(params, groups);
      }
    })();
    if (
      params?.id
        ? (document.title = `Editing Job ${params.id}`)
        : (document.title = `Creating Job`)
    );
    return () => {
      isCancelled.current = true;
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    let isMounted = true;
    reset(formData);

    const statusNotEditable =
      formData.status === JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL ||
      formData.status === JOB_OPPORTUNITY_STATUSES.ACTIVE ||
      formData.status === JOB_OPPORTUNITY_STATUSES.CANCELLED ||
      formData.status === JOB_OPPORTUNITY_STATUSES.FULFILLED;

    if (isManager() && isMounted) {
      setIsReadOnly(false);
    } else if (statusNotEditable && isMounted) {
      setIsReadOnly(true);
    } else if (!statusNotEditable && isMounted) {
      setIsReadOnly(false);
    }

    return () => {
      isMounted = false;
    };
  }, [isManager, formData, reset]);

  // Warn before leaving page
  useEffect(() => {
    const message = `You have unsaved changes. Are you sure you want to leave?`;

    // When navigating through browser controls OR through in-app links
    const unblock = history.block(() => {
      if (isDirtyAlt && !isReadOnly && !(saveAsPreview || saveAsDraft)) {
        if (window.confirm(message)) {
          unblock();

          return true;
        }

        return false;
      }

      return true;
    });

    function warn(evt) {
      if (isDirtyAlt && !isReadOnly) {
        evt.preventDefault();
        evt.returnValue = message;
      }
    }

    // When closing the browser (no client side router playing a role)
    window.addEventListener(`beforeunload`, warn);

    return () => {
      window.removeEventListener(`beforeunload`, warn);
      unblock();
    };
  }, [isDirtyAlt, isReadOnly, history, saveAsPreview, saveAsDraft]);

  useEffect(
    () => setRequiredPositions(formData.requiredPositions),
    [formData.requiredPositions]
  );

  useEffect(
    () => setJobLengthInWeeks(formData.jobLengthInWeeks),
    [formData.jobLengthInWeeks]
  );

  useEffect(() => {
    if (!debounceOrganization) return;
    (async () => {
      const { data } = await API.graphql(
        graphqlOperation(listCompanyUsers, {
          company: debounceOrganization,
          filter: { flagPrimaryMatchers: true },
        })
      );

      setCompanyUsers(data?.listCompanyUsers?.items);
      resetField("customerOwnerId");
      resetField("primaryMatcherId");

      if (user.company === getValues("organization")) {
        if (!params.id && !getValues("customerOwnerId")) {
          setValue("customerOwnerId", user?.id);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceOrganization, resetField]);

  if (initializingForm) {
    return (
      <div>
        <Header />
        <JobPageWrapper>
          <div className="flex justify-center h-96">
            <span className="loader"></span>
          </div>
        </JobPageWrapper>
        <Footer />
      </div>
    );
  }

  let confirmationActionText;

  if (formData.status === JOB_OPPORTUNITY_STATUSES.ACTIVE) {
    confirmationActionText = "Cancel Job";
  } else if (formData.status === JOB_OPPORTUNITY_STATUSES.CANCELLED) {
    confirmationActionText = "Restore Job";
  } else {
    confirmationActionText = "Delete Job";
  }

  let cannotEditText;

  if (formData.status === JOB_OPPORTUNITY_STATUSES.ACTIVE) {
    cannotEditText =
      "This job is active. While you can Cancel it, you cannot make any other changes to it.";
  } else if (formData.status === JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL) {
    cannotEditText =
      "This job is pending approval. You cannot make any changes to it.";
  } else if (formData.status === JOB_OPPORTUNITY_STATUSES.CANCELLED) {
    cannotEditText = "This job is canceled. You cannot make any changes to it.";
  } else if (formData.status === JOB_OPPORTUNITY_STATUSES.FULFILLED) {
    cannotEditText =
      "This job is completed. You cannot make any changes to it.";
  }

  let submitButtonText;

  if (formData.status === JOB_OPPORTUNITY_STATUSES.DRAFT) {
    submitButtonText = saveAsPreview ? "Generating preview..." : "Preview job";
  } else {
    submitButtonText = saveAsPreview ? "Saving..." : "Save job";
  }

  const scrollTextAreaIntoViewOnError = () => {
    if (saveAsDraft) return;
    if (errors.jobTypeId?.message || errors.title?.message) return;

    if (errors?.overview?.message) {
      return overviewRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.responsibilities?.message) {
      return responsibilitiesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.requirements?.message) {
      return requirementsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.skillsAreRequired?.message) {
      return skillsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.timezone?.message) {
      return timeZoneRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const generateDescription = async () => {
    const errorMessage =
      "Your description could not be generated using AI at this moment. Please try again or compose your own description.";

    setShortDescriptionObj((prev) => ({
      ...prev,
      errorMessage: null,
    }));

    try {
      const jobDetails = cloneDeep(getValues());

      jobDetails.geographicalRegions = getFormattedGeographicalRegions(
        jobDetails.geographicalRegions
      )[0];

      if (jobDetails.location?.id) {
        jobDetails.location = formatLocation(jobDetails.location);
      }

      const { data } = await API.graphql(
        graphqlOperation(generateJobShortDescription, {
          jobDetails: JSON.stringify(jobDetails),
        })
      );
      if (data.generateJobShortDescription === null) {
        throw new Error(errorMessage);
      } else {
        setValue(
          "shortDescription",
          data.generateJobShortDescription.shortDescription
        );
        return data.generateJobShortDescription.shortDescription;
      }
    } catch (err) {
      console.log(err);
      setShortDescriptionObj((prev) => ({
        ...prev,
        errorMessage,
      }));
      setValue("shortDescription", "");
    }
  };

  const handleGenerateDescription = async (e) => {
    e.preventDefault();
    setIsGeneratingDescription(true);

    if (shortDescriptionObj.errorMessage) {
      setShortDescriptionObj((prev) => ({
        ...prev,
        errorMessage: null,
      }));
    }
    if (errors.shortDescription?.message) clearErrors("shortDescription");

    if (errors.skillsAreRequired?.message) clearErrors("skillsAreRequired");

    const formHasFieldsForJobDescription =
      (await trigger(SHORT_DESCRIPTION_VALIDATION_FIELDS, {
        shouldFocus: true,
      })) && checkFormJobHasSkills(getValues());

    if (formHasFieldsForJobDescription) {
      setValue(
        "shortDescription",
        "Your AI-generated job description is on its way! \nJust a moment as we process the job details..."
      );
      await generateDescription();
    } else {
      handleOnErrors();
    }

    setIsGeneratingDescription(false);
  };

  const handleOnChangeForm = () => {
    for (const field of SHORT_DESCRIPTION_VALIDATION_FIELDS) {
      if (errors[field]?.message) {
        return trigger(SHORT_DESCRIPTION_VALIDATION_FIELDS);
      }
    }
  };

  // "by region" behaviour
  // - When a region is selected, all countries in that region are added
  // - country cannot be selected
  //
  // "by country" behaviour
  // - When a region is selected, all countries in that region are added
  // - AND
  // - When a country is selected, only that country is added
  // ! During save, we remove the countries and only keep region if all countries in that region are present
  // ! but internally, we are still dealing with all countries in that region
  //
  // "by city" behaviour
  // - Straightforward - a dropdown of locations is present and user can select
  // one from it. This sets the city (and state) of the selected location
  //
  // When switching from "by country" to "by region", if a country is present in the selection
  // that country is lost because "by region" won't allow it
  // When switching from "by city" to the others or vice versa, the selection is lost completely
  // since neither is compatible with the other.
  const handleGeoRegionTypeChange = (newType) => {
    if (activeGeoRegionType === GEO_REGION_TYPE.BY_COUNTRY) {
      const [formattedGeoRegions, regionMapping] =
        getFormattedGeographicalRegions(getValues("geographicalRegions"));

      const regions = [];

      for (let i = 0; i < formattedGeoRegions.length; i++) {
        if (formattedGeoRegions[i].countryNames.length === 0) {
          const region = formattedGeoRegions[i].regionName;
          const countries = regionMapping[region];

          regions.push(
            ...countries.map((c) => ({
              label: c,
              value: getCountryValue(region, c),
            }))
          );
        }
      }

      setValue("geographicalRegions", regions);
    }

    if (newType === GEO_REGION_TYPE.BY_CITY) {
      setValue("geographicalRegions", []);
    } else {
      setValue("location", {});
    }
    setActiveGeoRegionType(newType);
  };

  // filter out logged-in user if he is not admin (internal user) since torcOwners should only be internal users
  const primaryMatchers = companyUsers?.filter(
    ({ canBePrimaryMatcher }) => canBePrimaryMatcher === true
  );

  const canSubmit =
    isManager() ||
    (formData.status !== JOB_OPPORTUNITY_STATUSES.FULFILLED &&
      formData.status !== JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL &&
      formData.status !== JOB_OPPORTUNITY_STATUSES.CANCELLED);

  const handleCurrencyOnChange = async (e) => {
    try {
      let factor = e.factor;

      if (!factor) {
        const currencyApiClient = getCurrencyApiClient();

        const { data } = await currencyApiClient.latest({
          currencies: "USD",
          base_currency: e.value,
        });

        factor = data["USD"].value;
      }

      const currentFromValues = getValues();

      const minValue =
        currentFromValues[
          compensationType === "ratePerHour" ? "minRate" : "minSalary"
        ];

      const newBaseMinValue = Math.ceil(currencyFactor * minValue);

      const maxValue =
        currentFromValues[
          compensationType === "ratePerHour" ? "maxRate" : "maxSalary"
        ];

      const newBaseMaxValue = Math.ceil(currencyFactor * maxValue);

      const newMinConvertedValue = baseToTargetCurrency(
        newBaseMinValue,
        factor
      );

      const newMaxConvertedValue = baseToTargetCurrency(
        newBaseMaxValue,
        factor
      );

      currencyConfigRef.current.currentCurrency = {
        ...e,
        factor,
      };

      compensationType === "ratePerHour"
        ? setValue("minRate", newMinConvertedValue)
        : setValue("minSalary", newMinConvertedValue);

      compensationType === "ratePerHour"
        ? setValue("maxRate", newMaxConvertedValue)
        : setValue("maxSalary", newMaxConvertedValue);
    } catch (err) {
      console.log(err);
    }
  };

  realUSDRate.current = {
    ...realUSDRate.current,
    min:
      compensationType === "ratePerHour" &&
      currencyFactor &&
      currencyFactor !== 1
        ? parseInt(
            Math.ceil(Number((currencyFactor * watchMinRate).toFixed(2)))
          )
        : undefined,
    max:
      compensationType === "ratePerHour" &&
      currencyFactor &&
      currencyFactor !== 1
        ? parseInt(
            Math.ceil(Number((currencyFactor * watchMaxRate).toFixed(2)))
          )
        : undefined,
  };

  realUSDSalary.current = {
    ...realUSDSalary.current,
    min:
      compensationType === "salary" && currencyFactor && currencyFactor !== 1
        ? parseInt(
            Math.ceil(Number((currencyFactor * watchMinSalary).toFixed(2)))
          )
        : undefined,
    max:
      compensationType === "salary" && currencyFactor && currencyFactor !== 1
        ? parseInt(
            Math.ceil(Number((currencyFactor * watchMaxSalary).toFixed(2)))
          )
        : undefined,
  };

  const renderUSDValue = (number, showDecimal = false) => {
    const [integerPart, decimalPart] = number.toString().split(".");

    return (
      <>
        ($ {integerPart}
        {showDecimal && decimalPart && (
          <>
            .<span className="text-gray-400">{decimalPart}</span>
          </>
        )}
        )
      </>
    );
  };

  const instructionsType = currencyConversionIsEnabled
    ? "conversion"
    : "default";

  return (
    <div>
      <Header />
      <JobPageWrapper>
        <FormProvider {...methods}>
          <form
            className="max-w-2xl mx-auto my-6 md:my-12 px-4 flex flex-col gap-8 pb-12"
            onSubmit={handleSubmit(onPreview, handleOnErrors)}
            onChange={handleOnChangeForm}
          >
            {isReadOnly && !!cannotEditText && (
              <div
                className="flex bg-blue-100 rounded-lg p-4 mb-4 text-sm text-blue-700"
                role="alert"
              >
                <svg
                  className="w-5 h-5 inline mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <span className="font-medium">NOTE:</span> {cannotEditText}
                </div>
              </div>
            )}

            <div className="flex items-start flex-col md:flex-row gap-4 md:gap-12">
              <div>
                <h1 className="text-3xl font-bold">
                  {params?.id && "Edit"}
                  {!params?.id && "Post a new"}&nbsp;job
                </h1>
                <p>
                  Let's start building your team. Tell us a little about the
                  position so we can find the perfect developer match.
                </p>
              </div>
            </div>

            {!isReadOnly && !!formData.status && (
              <p className="font-sans">
                <span className="font-bold">Status: </span>
                <span className="inline-block lowercase first-letter:uppercase">
                  {formatJobStatus(formData.status)}
                </span>
              </p>
            )}

            {isAdmin() && (
              <Section>
                <Label>
                  Company
                  <InfoPopover>
                    The company to associate this job with (for team access). If
                    unspecified, it will default to the company associated with
                    your own account.
                  </InfoPopover>
                </Label>
                <Input {...register(`organization`)} isReadOnly={isReadOnly} />
                <FormError>{errors.organization?.message}</FormError>
              </Section>
            )}

            {(isAdmin() || companyUsers?.length > 1) && (
              <Section>
                <Label>Customer Owner</Label>
                <Select {...register(`customerOwnerId`)}>
                  <option value="">Select customer owner</option>
                  {companyUsers?.map(({ id, username }) => (
                    <option key={id} value={id}>
                      {username}
                    </option>
                  ))}
                </Select>
                <FormError>{errors.customerOwnerId?.message}</FormError>
              </Section>
            )}

            {isPremiumCustomer && primaryMatchers.length > 0 && (
              <Section>
                <Label>Primary Matcher</Label>
                <Select {...register(`primaryMatcherId`)}>
                  <option value="">Select primary matcher</option>
                  {primaryMatchers?.map(({ id, username }) => (
                    <option key={id} value={id}>
                      {username}
                    </option>
                  ))}
                </Select>
                <FormError>{errors.primaryMatcherId?.message}</FormError>
              </Section>
            )}

            {(isAdmin() || isPremiumCustomer) && (
              <Section>
                <Label>
                  External Job ID
                  <InfoPopover>
                    The ID of the job from its original external source, for
                    reference.
                  </InfoPopover>
                </Label>
                <Input {...register(`externalJobId`)} isReadOnly={isReadOnly} />
                <FormError>{errors.externalJobId?.message}</FormError>
              </Section>
            )}

            <Section>
              <Label>
                Job role
                <InfoPopover>
                  Select the job role that best describes your ideal developer.
                  We use job roles to assess and qualify developers in our
                  community so we can expedite the matching process.
                </InfoPopover>
              </Label>
              <Select {...register(`jobTypeId`)} isReadOnly={isReadOnly}>
                <option value="">Select job role</option>
                {jobTypes?.map(({ id, title }) => (
                  <option key={id} value={id}>
                    {title}
                  </option>
                ))}
              </Select>
              <FormError>{errors.jobTypeId?.message}</FormError>
            </Section>

            <Section>
              <Label>Employment Type</Label>
              <Select {...register("employmentType")} isReadOnly={isReadOnly}>
                <option value="">Select Job Type</option>
                {Object.entries(EMPLOYMENT_TYPES).map(([value, name]) => (
                  <option key={value} id={value} value={value}>
                    {name}
                  </option>
                ))}
              </Select>
              <FormError>{errors.employmentType?.message}</FormError>
            </Section>

            {isPremiumCustomer && (
              <Section>
                <Label>
                  Priority
                  <InfoPopover>
                    Select the priority for this job opportunity. This helps
                    determine the priority of jobs to be handled first.
                  </InfoPopover>
                </Label>
                <Select {...register(`priorityLevel`)} isReadOnly={isReadOnly}>
                  <option value="">Select Priority</option>
                  {Object.values(JOB_OPPORTUNITY_PRIORITY_LEVELS)?.map(
                    (value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    )
                  )}
                </Select>
              </Section>
            )}

            <Section>
              <Label>
                Job title{" "}
                <InfoPopover>
                  A clear and descriptive job title can make all the difference.
                </InfoPopover>
              </Label>
              <Input {...register(`title`)} isReadOnly={isReadOnly} />
              <FormError>{errors.title?.message}</FormError>
            </Section>
            <Section>
              <div ref={overviewRef} />

              <Label>
                Job overview
                <InfoPopover>
                  Use this space to provide information about the position that
                  will attract top developers. One or two short paragraphs
                  usually works best. Potential topics include:
                  <li>Description of your company</li>
                  <li>General description of the position</li>
                  <li>
                    The team the developer will be joining, as well as other
                    teams they'll be working with
                  </li>
                  <li>
                    {" "}
                    Any unique or interesting characteristics about your company
                    or the position
                  </li>
                </InfoPopover>
              </Label>
              <MarkdownEditor
                name="overview"
                control={control}
                isReadOnly={isReadOnly}
              />
              <FormError>{errors.overview?.message}</FormError>
            </Section>
            <Section>
              <div ref={responsibilitiesRef} />
              <Label>
                Job responsibilities
                <InfoPopover>
                  In bullet point format, describe the activities and
                  deliverables the developer is responsible for completing.
                  Clear and specific responsibilities attract more developers.{" "}
                  <br />
                  <br />
                  We recommend 5-7 bullet points
                </InfoPopover>
              </Label>
              <MarkdownEditor
                name="responsibilities"
                control={control}
                isReadOnly={isReadOnly}
              />
              <FormError>{errors.responsibilities?.message}</FormError>
            </Section>
            <Section>
              <div ref={requirementsRef} />
              <Label>
                Job requirements
                <InfoPopover>
                  In bullet point format, describe the requirements for this
                  job. We recommend 5-7 bullet points.
                  <br />
                  <br />
                  Keep in mind that it's easy to turn away developers with
                  unclear, overly complex, or unrealistic requirements. Below
                  are a few specific recommendations based on feedback from our
                  community and review of successful job postings:
                  <li>
                    Years of experience is no longer an accurate measure of a
                    developer's skill level. We do not recommend including an
                    experience requirement, but instead ensure your other
                    requirements accurately reflect the skill level that is
                    needed for the developer to be successful.
                  </li>
                  <li>
                    Clearly denote 'optional' or 'nice-to-have' requirements
                  </li>
                </InfoPopover>
              </Label>
              <MarkdownEditor
                name="requirements"
                control={control}
                isReadOnly={isReadOnly}
              />
              <FormError>{errors.requirements?.message}</FormError>
            </Section>
            <div ref={skillsRef} />
            <Section>
              <Label>
                Skills{" "}
                <InfoPopover>
                  Add all required skills for the job. <br />
                  <br />
                  To add skills, begin typing the skill name and select the
                  appropriate skill from the resulting list. Add additional
                  skills using the same method.
                </InfoPopover>
              </Label>
              <SkillSearch
                name="skills"
                control={control}
                register={register}
                isReadOnly={isReadOnly}
              />
            </Section>
            <Section>
              <Label>
                Optional Skills{" "}
                <InfoPopover>
                  Add optional skills for the job. <br />
                  <br />
                  To add skills, begin typing the skill name and select the
                  appropriate skill from the resulting list. Add additional
                  skills using the same method.
                </InfoPopover>
              </Label>
              <SkillSearch
                name="optionalSkills"
                control={control}
                register={register}
                isReadOnly={isReadOnly}
              />
            </Section>
            <FormError>{errors.skillsAreRequired?.message}</FormError>

            <div className="border border-gray-300 rounded-full text-gray-400 w-fit">
              <span
                className={classNames(
                  "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-l-full text-center font-normal inline-block border-r border-gray-300",
                  {
                    "bg-blue-500 text-white":
                      compensationType === "ratePerHour",
                  }
                )}
                onClick={() => setCompensationType("ratePerHour")}
              >
                Hourly Rate
              </span>
              <span
                className={classNames(
                  "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-r-full text-center font-normal inline-block border-r border-l border-gray-300",
                  {
                    "bg-blue-500 text-white": compensationType === "salary",
                  }
                )}
                onClick={() => setCompensationType("salary")}
              >
                Yearly Salary
              </span>
            </div>

            {compensationType === "ratePerHour" && (
              <Section>
                <Label>
                  Hourly rate range
                  <InfoPopover>
                    {rateInstructions[instructionsType].hourlyRate}
                  </InfoPopover>
                </Label>
                {currencyConversionIsEnabled && (
                  <CurrencySelect
                    isLoading={isLoadingCurrencies}
                    options={getCurrencyMappedOptions(
                      currencyConfigRef.current.currencies
                    )}
                    defaultValue={currencyConfigRef.current.currentCurrency}
                    onChange={handleCurrencyOnChange}
                  />
                )}

                <div className="flex w-full gap-2 flex-wrap sm:flex-nowrap">
                  <div className="flex flex-col gap-2 w-full sm:w-fit">
                    <div className="flex items-center gap-2 justify-between w-full">
                      <span className="w-[40px] sm:w-fit">Min</span>

                      <Input
                        className="w-full sm:w-48"
                        type="number"
                        isReadOnly={isReadOnly}
                        {...register("minRate")}
                        onBlur={(e) => handleRateChange(e, "minRate")}
                      />

                      <span className="w-24 sm:w-fit whitespace-nowrap">
                        {realUSDRate.current.min !== undefined ? (
                          <>{renderUSDValue(realUSDRate.current.min)}</>
                        ) : (
                          <>$</>
                        )}
                      </span>
                    </div>

                    <FormError>{errors.minRate?.message}</FormError>
                  </div>

                  <div className="flex flex-col gap-2 w-full sm:w-fit">
                    <div className="flex items-center gap-2 w-full">
                      <span className="w-[40px] sm:w-fit">Max</span>

                      <Input
                        className="w-full sm:w-48"
                        type="number"
                        isReadOnly={isReadOnly}
                        name="maxRate"
                        {...register("maxRate")}
                        onBlur={(e) => handleRateChange(e, "maxRate")}
                      />

                      <span className="w-24 sm:w-fit whitespace-nowrap">
                        {realUSDRate.current.max !== undefined ? (
                          <>{renderUSDValue(realUSDRate.current.max)}</>
                        ) : (
                          <>$</>
                        )}
                      </span>
                    </div>

                    <FormError>{errors.maxRate?.message}</FormError>
                  </div>
                </div>
              </Section>
            )}
            {compensationType === "salary" && (
              <Section>
                <Label>
                  Yearly salary range
                  <InfoPopover>
                    {rateInstructions[instructionsType].yearlyRate}
                  </InfoPopover>
                </Label>
                {currencyConversionIsEnabled && (
                  <CurrencySelect
                    options={getCurrencyMappedOptions(
                      currencyConfigRef.current.currencies
                    )}
                    defaultValue={currencyConfigRef.current.currentCurrency}
                    onChange={handleCurrencyOnChange}
                  />
                )}

                <div className="flex w-full gap-2 flex-wrap sm:flex-nowrap">
                  <div className="flex flex-col gap-2 w-full sm:w-fit">
                    <div className="flex items-center gap-2 justify-between w-full">
                      <span className="w-[40px] sm:w-fit">Min</span>

                      <Input
                        className="w-full sm:w-48"
                        type="number"
                        isReadOnly={isReadOnly}
                        {...register("minSalary")}
                        onBlur={(e) => handleSalaryChange(e, "minSalary")}
                      />

                      <span className="w-32 sm:w-fit whitespace-nowrap">
                        {realUSDSalary.current.min !== undefined ? (
                          <>{renderUSDValue(realUSDSalary.current.min)}</>
                        ) : (
                          <>$</>
                        )}
                      </span>
                    </div>

                    <FormError>{errors.minSalary?.message}</FormError>
                  </div>

                  <div className="flex flex-col gap-2 w-full sm:w-fit">
                    <div className="flex items-center gap-2 w-full">
                      <span className="w-[40px] sm:w-fit">Max</span>

                      <Input
                        className="w-full sm:w-48"
                        type="number"
                        isReadOnly={isReadOnly}
                        name="maxSalary"
                        {...register("maxSalary")}
                        onBlur={(e) => handleSalaryChange(e, "maxSalary")}
                      />

                      <span className="w-32 sm:w-fit whitespace-nowrap">
                        {realUSDSalary.current.max !== undefined ? (
                          <>{renderUSDValue(realUSDSalary.current.max)}</>
                        ) : (
                          <>$</>
                        )}
                      </span>
                    </div>

                    <FormError>{errors.maxSalary?.message}</FormError>
                  </div>
                </div>
              </Section>
            )}
            <Section>
              <Label>
                Time commitment{" "}
                <InfoPopover>
                  Select whether your job is Full-time (40 hours per week) or
                  Half-time (20 hours per week).
                </InfoPopover>
              </Label>
              <div className="flex gap-8">
                <div>
                  <Radio
                    value="FULLTIME"
                    {...register(`timeCommitment`)}
                    isReadOnly={isReadOnly}
                  />
                  <label>Full-time</label>
                </div>
                <div>
                  <Radio
                    value="PARTTIME"
                    {...register(`timeCommitment`)}
                    isReadOnly={isReadOnly}
                  />
                  <label>Half-time</label>
                </div>
              </div>
              <FormError>{errors.timeCommitment?.message}</FormError>
            </Section>
            <Section>
              <Label>
                Positions <InfoPopover>Number of Open Positions. </InfoPopover>
              </Label>
              <div className="flex w-full">
                <div className="flex flex-col gap-2 w-full mr-3">
                  <div className="flex items-center w-full">
                    <Input
                      className="w-11/12"
                      type="number"
                      isReadOnly={isReadOnly}
                      {...register("requiredPositions")}
                      onBlur={(e) =>
                        handleNumberFieldChange(e, "requiredPositions")
                      }
                    />

                    <span className="ml-2 text-right w-max">
                      {requiredPositions > 1 ? "positions" : "position"}
                    </span>
                  </div>

                  <FormError>{errors.requiredPositions?.message}</FormError>
                </div>
              </div>
            </Section>
            {watchEmploymentType !== "PERMPLACEMENT" && (
              <Section>
                <Label>
                  Contract length{" "}
                  <InfoPopover>
                    We can accommodate contracts as short as 4 weeks and as long
                    as 52 weeks.{" "}
                  </InfoPopover>
                </Label>
                <div className="flex w-full">
                  <div className="flex flex-col gap-2 w-full mr-3">
                    <div className="flex items-center w-full">
                      <Input
                        className="w-11/12"
                        type="number"
                        isReadOnly={isReadOnly}
                        {...register("jobLengthInWeeks")}
                        onBlur={(e) =>
                          handleNumberFieldChange(e, "jobLengthInWeeks")
                        }
                      />

                      <span className="ml-2 text-right w-max">
                        {jobLengthInWeeks > 1 ? "weeks" : "week"}
                      </span>
                    </div>

                    <FormError>{errors.jobLengthInWeeks?.message}</FormError>
                  </div>
                </div>
              </Section>
            )}
            <Section>
              <Label>
                Desired start date
                <InfoPopover>
                  On which date would you like your developer to begin working?
                  Please choose today's date if you would like to post this job
                  with an “Immediate” start date.
                </InfoPopover>
              </Label>
              <Input
                type="date"
                focusDatePicker
                {...register(`startDate`)}
                isReadOnly={isReadOnly}
              />
              <FormError>{errors.startDate?.message}</FormError>
            </Section>
            <Section>
              <div ref={timeZoneRef} />
              <Label>
                Primary time zone
                <InfoPopover>
                  Select the time zone where your company (or the team with
                  which the developer will work) is located.
                </InfoPopover>
              </Label>
              <TimezoneSelect
                name="timezone"
                control={control}
                isReadOnly={isReadOnly}
              />
              <FormError>{errors.timezone?.value?.message}</FormError>
            </Section>
            <Section>
              <Label>
                Working time overlap{" "}
                <InfoPopover>
                  Select the amount of overlap you require between your primary
                  time zone and that of your developer.
                  <li>Full - developer must work on my time zone</li>
                  <li>At least 4 hours of overlap each workday</li>
                  <li>At least 2 hours of overlap each workday</li>
                  <li>No overlap required</li>
                </InfoPopover>
              </Label>
              <Select {...register(`timeOverlap`)} isReadOnly={isReadOnly}>
                <option value="">Select time overlap</option>
                {/* Seems like a bug with amplify that replaces the value 0 with null when passing input in the api */}
                <option value={-1}>No restriction</option>
                <option value={2}>2 hours</option>
                <option value={4}>4 hours</option>
                <option value={8}>All hours</option>
              </Select>
              <FormError>{errors.timeOverlap?.message}</FormError>
            </Section>
            {/* Backward compatibility - display regions if it exists */}
            {params?.id && formData.regions?.length > 0 && (
              <Section>
                <Label>
                  Developer location{" "}
                  <InfoPopover>
                    Select all regions where your developer may reside
                  </InfoPopover>
                </Label>
                <RegionSearch
                  name="regions"
                  control={control}
                  register={register}
                  isReadOnly={isReadOnly}
                />
                <FormError>{errors.geographicalRegions?.message}</FormError>
              </Section>
            )}
            {/*
              Geographical regions is displayed only if its a job creation
              or if there are no regions to show
             */}
            {(!params?.id || !formData.regions?.length > 0) && (
              <Section>
                <Label className="flex justify-between items-center">
                  <div>
                    Developer location{" "}
                    <InfoPopover>
                      Select all geographical regions where your developer may
                      reside
                    </InfoPopover>
                  </div>
                  {!isReadOnly && (
                    <div className="border border-gray-300 rounded-full text-gray-400">
                      <span
                        className={classNames(
                          "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-l-full text-center font-normal inline-block border-r border-gray-300",
                          {
                            "bg-blue-500 text-white":
                              activeGeoRegionType === GEO_REGION_TYPE.BY_REGION,
                          }
                        )}
                        onClick={() =>
                          handleGeoRegionTypeChange(GEO_REGION_TYPE.BY_REGION)
                        }
                      >
                        By Region
                      </span>
                      <span
                        className={classNames(
                          "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white text-center font-normal inline-block",
                          {
                            "bg-blue-500 text-white":
                              activeGeoRegionType ===
                              GEO_REGION_TYPE.BY_COUNTRY,
                          }
                        )}
                        onClick={() =>
                          handleGeoRegionTypeChange(GEO_REGION_TYPE.BY_COUNTRY)
                        }
                      >
                        By Country
                      </span>
                      <span
                        className={classNames(
                          "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-r-full text-center font-normal inline-block border-r border-l border-gray-300",
                          {
                            "bg-blue-500 text-white":
                              activeGeoRegionType === GEO_REGION_TYPE.BY_CITY,
                          }
                        )}
                        onClick={() =>
                          handleGeoRegionTypeChange(GEO_REGION_TYPE.BY_CITY)
                        }
                      >
                        By City
                      </span>
                    </div>
                  )}
                </Label>

                {activeGeoRegionType !== GEO_REGION_TYPE.BY_CITY && (
                  <GeographicalRegionSearch
                    onChange={(values) =>
                      setValue("geographicalRegions", values)
                    }
                    value={watch("geographicalRegions")}
                    isReadOnly={isReadOnly}
                    geoRegiontype={
                      isReadOnly
                        ? GEO_REGION_TYPE.BY_COUNTRY
                        : activeGeoRegionType
                    }
                    key={activeGeoRegionType}
                  />
                )}

                {activeGeoRegionType === GEO_REGION_TYPE.BY_CITY && (
                  <SearchLocations
                    location={watch("location")}
                    setLocation={(loc) =>
                      setValue("location", loc, {
                        shouldValidate: true,
                      })
                    }
                  />
                )}

                <FormError>{errors.geographicalRegions?.message}</FormError>
              </Section>
            )}

            {showRequiredExperience && (
              <Section>
                <Label>
                  Required Overall Experience{" "}
                  <InfoPopover>
                    Enter the minimum number of years of professional experience
                    required for this role. Include relevant experience that
                    aligns with the job responsibilities.
                  </InfoPopover>
                </Label>
                <div className="flex w-full">
                  <div className="flex flex-col gap-2 w-full mr-3">
                    <div className="flex items-center w-full">
                      <Input
                        className="w-11/12"
                        type="number"
                        isReadOnly={isReadOnly}
                        {...register("requiredExperience")}
                        onBlur={(e) =>
                          handleNumberFieldChange(e, "requiredExperience")
                        }
                      />

                      <span className="ml-2 text-right w-max">year(s)</span>
                    </div>

                    <FormError>{errors.requiredExperience?.message}</FormError>
                  </div>
                </div>
              </Section>
            )}

            <Section>
              <Label>
                Work Mode{" "}
                <InfoPopover>
                  Describe the manner in which the work is expected to be
                  performed
                </InfoPopover>
              </Label>
              <div className="flex gap-8">
                <div>
                  <Radio
                    value="HYBRID"
                    {...register(`workMode`)}
                    isReadOnly={isReadOnly}
                  />
                  <label>Hybrid</label>
                </div>
                <div>
                  <Radio
                    value="OFFICE"
                    {...register(`workMode`)}
                    isReadOnly={isReadOnly}
                  />
                  <label>Office</label>
                </div>
                <div>
                  <Radio
                    value="REMOTE"
                    {...register(`workMode`)}
                    isReadOnly={isReadOnly}
                  />
                  <label>Remote</label>
                </div>
              </div>
              <FormError>{errors.workMode?.message}</FormError>
            </Section>
            <Section>
              <Label>
                Short Description
                <InfoPopover>
                  <p>Provide a short description of your job.</p>
                  <p>
                    Click the black icon to let the magic of AI generate one for
                    you!
                  </p>
                </InfoPopover>
              </Label>
              <div
                className={classNames({
                  "animate-pulse": isGeneratingDescription,
                })}
              >
                <Textarea
                  id="shortDescription"
                  value={getValues("shortDescription")}
                  isReadOnly={isReadOnly || isGeneratingDescription}
                  bgColor={isGeneratingDescription ? "bg-gray-100" : ""}
                  paddingRight="16"
                  onChange={({ target }) => {
                    if (target.value.trim().split(/\s+/).length <= 250) {
                      setValue("shortDescription", target.value);
                    }
                  }}
                  rows={9}
                  className={classNames(
                    "p-1 pb-0 bg-gradient-to-r from-[#83D9BB] to-[#F4D675] rounded-[10px]"
                  )}
                  borderColor="transparent"
                >
                  {!isReadOnly && (
                    <button
                      onClick={handleGenerateDescription}
                      disabled={isGeneratingDescription}
                      title={
                        isGeneratingDescription
                          ? "Generating..."
                          : "Click to generate"
                      }
                    >
                      <SvgIcon
                        type="bot"
                        className={classNames(
                          "absolute top-3 right-[25px] min-w-[50px] min-h-[50px]",
                          "rounded-full text-white bg-gray-500 !p-2",
                          {
                            "animate-pulse cursor-default":
                              isGeneratingDescription,
                          },
                          {
                            "hover:scale-110 hover:bg-gray-400":
                              !isGeneratingDescription,
                          }
                        )}
                      />
                    </button>
                  )}
                </Textarea>
              </div>
              <div
                className={classNames("flex justify-end", {
                  "justify-between": errors.shortDescription?.message,
                })}
              >
                <FormError>{errors.shortDescription?.message}</FormError>
                {!isGeneratingDescription && (
                  <p>
                    {shortDescriptionObj.words} / 250{" "}
                    <span className="text-xs">words</span>
                  </p>
                )}
              </div>
              <div
                className={classNames("flex justify-end", {
                  "justify-between": shortDescriptionObj.errorMessage,
                })}
              >
                <FormError> {shortDescriptionObj.errorMessage}</FormError>
              </div>
            </Section>
            {canAccessJobVisibility() && (
              <Section>
                <Label>
                  Visibility{" "}
                  <InfoPopover>
                    Limited means only Professional Community members can see
                    it, Public means anyone with the corresponding Job Role can
                    see it, Private means only Matched candidates can see it
                  </InfoPopover>
                </Label>
                <Select
                  {...register(`visibilityLevel`)}
                  isReadOnly={isReadOnly}
                  value={visibility}
                  onChange={handleChange}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Section>
            )}
            <div className="flex flex-col gap-2">
              <div className="flex justify-between gap-4">
                <div className="sm:block flex flex-col">
                  {(!formData.status ||
                    formData.status === JOB_OPPORTUNITY_STATUSES.DRAFT) && (
                    <Button
                      onClick={saveDraft}
                      isReadOnly={isGeneratingDescription}
                    >
                      {saveAsDraft ? "Saving..." : "Save draft"}
                    </Button>
                  )}
                  {(formData.status === JOB_OPPORTUNITY_STATUSES.ACTIVE ||
                    formData.status === JOB_OPPORTUNITY_STATUSES.CANCELLED ||
                    formData.status === JOB_OPPORTUNITY_STATUSES.FULFILLED ||
                    formData.status ===
                      JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL) && (
                    <Button
                      onClick={() => void viewJobs()}
                      isReadOnly={isGeneratingDescription}
                    >
                      View My Jobs
                    </Button>
                  )}
                  {formData.status &&
                    formData.status !== JOB_OPPORTUNITY_STATUSES.FULFILLED &&
                    formData.status !== JOB_OPPORTUNITY_STATUSES.CANCELLED &&
                    formData.status !==
                      JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL && (
                      <>
                        <Button
                          onClick={() => void setIsConfirmationModalOpen(true)}
                          isReadOnly={
                            (formData.status !==
                              JOB_OPPORTUNITY_STATUSES.ACTIVE &&
                              formData.status !==
                                JOB_OPPORTUNITY_STATUSES.DRAFT &&
                              formData.status) ||
                            isGeneratingDescription
                          }
                          className="sm:ml-4 sm:mt-0 mt-4"
                          bgColor="bg-black"
                          text="text-white"
                        >
                          {confirmationActionText}
                        </Button>
                        <ConfirmationModal
                          open={isConfirmationModalOpen}
                          onClose={() => void setIsConfirmationModalOpen(false)}
                          onConfirm={onConfirmAction}
                          status={formData.status}
                          actionInProgress={confirmingAction}
                        />
                      </>
                    )}
                </div>
                {canSubmit && (
                  <div
                    style={{
                      maxHeight: "70px",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={() => trigger()}
                      className="flex items-center sm:mr-0 mr-14"
                      isReadOnly={isGeneratingDescription}
                    >
                      {submitButtonText}
                      <img src={rightArrow} alt="" className="ml-1" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </JobPageWrapper>
      <Footer />
    </div>
  );
}

function Section({ children, className = `` }) {
  return <div className={`flex flex-col gap-2` + className}>{children}</div>;
}

function Label({ children, className = "" }) {
  return <label className={`font-bold ${className}`}>{children}</label>;
}
