import classNames from "classnames";

import Article from "./Article";

function parseArticles(articles) {
  let featuredArticleIdx = 0;

  for (let i = 0; i < articles.length; i++) {
    const article = articles[i];
    const tagList = article.tag_list;
    if (tagList.includes("Featured")) {
      featuredArticleIdx = i;
    }
  }

  const notFeatured = articles.filter((_art, i) => i !== featuredArticleIdx);

  return [articles[featuredArticleIdx], notFeatured];
}

function Articles({ articles }) {
  if (!articles) {
    return null;
  }

  const [featured, rest] = parseArticles(articles);

  const notFeatured = rest.slice(0, 2);

  return (
    <div
      className={classNames(
        "flex md:flex-row md:justify-between w-full flex-col relative gap-24"
      )}
    >
      <Article featured article={featured} key={"featured-article-1"} />
      {notFeatured && notFeatured.length > 0 ? (
        <div className="flex flex-col w-full gap-x-3 gap-y-4 max-h-full">
          {notFeatured.map((article, i) => (
            <Article article={article} key={`article-${i}`} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Articles;
