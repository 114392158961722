import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import HeadShot from "./molecules/HeadShot";
import UtilsLib from "utils/lib";
import Modal from "components/Modal";
import SimilarUserCard from "./molecules/SimilarUserCard";
import HoverTooltip from "components/HoverTooltip";
import classNames from "classnames";
import { getScoreClasses } from "./molecules/helper";
import { getLocalTime, getTimeZoneId } from "helpers/utils";

const SimilarUser = ({ profileUser, similarUser: incomingSimilarUser }) => {
  const { headshotKey, username, vectorSearchScore, identity_username } =
    incomingSimilarUser;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [similarUser, setSimilarUser] = useState({
    ...incomingSimilarUser,
    similarity: { isLoading: true },
  });

  const formatSimilarityUserFieldsToText = (user) => {
    const fieldsOfInterest = [
      "bio",
      "skills",
      "knownLanguages",
      "location",
      "careers",
      "availability",
    ];

    const formatArray = [];
    const inputFieldsOfInterest = [];

    for (const field of fieldsOfInterest) {
      const fieldValue = user[field];
      if (fieldValue) {
        if (field === "skills" && fieldValue.length > 0) {
          formatArray.push(
            `${field}: ${fieldValue.map(({ name }) => name).join(", ")}`
          );
          inputFieldsOfInterest.push(field);
        } else if (field === "knownLanguages" && fieldValue.length > 0) {
          formatArray.push(
            `known languages: ${fieldValue
              .map(({ language }) => language)
              .join(", ")}`
          );
          inputFieldsOfInterest.push("known languages");
        } else if (field === "location" && fieldValue.countryName) {
          formatArray.push(`country: ${fieldValue.countryName}`);
          inputFieldsOfInterest.push("country");
        } else if (field === "careers" && fieldValue.length > 0) {
          formatArray.push(
            `${field}: ${fieldValue
              .map(
                ({ title, stack, description }) =>
                  `${title} ${stack} ${description}`
              )
              .join(" | ")}`.replace(/\n/g, "")
          );
          inputFieldsOfInterest.push(field);
        } else {
          formatArray.push(`${field}: ${fieldValue}`);
          inputFieldsOfInterest.push(field);
        }
      }
    }
    return {
      data: formatArray.filter((e) => e).join("\n\n"),
      fieldsOfInterest: inputFieldsOfInterest.join(", "),
    };
  };

  const { data: referenceUserData, fieldsOfInterest } =
    formatSimilarityUserFieldsToText(profileUser);

  const { data: similarUserData } =
    formatSimilarityUserFieldsToText(incomingSimilarUser);

  useEffect(() => {
    (async () => {
      const newSimilarUser = {
        ...incomingSimilarUser,
      };

      newSimilarUser.similarity = await UtilsLib.User.getUserSimilarityFeedback(
        {
          referenceUsername:
            profileUser.username || profileUser.identity_username,
          referenceUserData,
          fieldsOfInterest,
          similarUsername:
            newSimilarUser.username || newSimilarUser.identity_username,
          similarUserData,
        }
      );

      const timeZoneId = await getTimeZoneId(newSimilarUser.location);

      if (timeZoneId) {
        newSimilarUser.timeZoneId = timeZoneId;
        newSimilarUser.localTime = await getLocalTime(timeZoneId);
      }

      setSimilarUser(newSimilarUser);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-1/4 flex justify-center items-center px-4">
        <HoverTooltip
          placement="top"
          modifiers={[
            {
              name: "offset",
              enabled: true,
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "preventOverflow",
              enabled: false,
            },
          ]}
          hoverTextClassName={classNames(
            "!bg-transparent !w-[110px] !transition-all !ease-in-out !duration-700",
            getScoreClasses(vectorSearchScore, 86).toolTip
          )}
          hoverText={
            <div className="inline-block px-2 py-0.5 text-center rounded-full text-xs whitespace-nowrap font-medium">
              {`${Math.trunc(vectorSearchScore)}%`} Compatible
            </div>
          }
        >
          <div className="flex flex-col items-center justify-center gap-y-4 !max-w-[64px] sm:!max-w-[75px]">
            <div onClick={() => setIsModalOpen(true)}>
              <HeadShot headshotKey={headshotKey} score={vectorSearchScore} />
            </div>
            <Link
              to={`/profile/${username || identity_username}`}
              target="_blank"
              className="w-full"
            >
              <p className="text-black hover:underline font-semibold text-xs truncate text-center">
                @{username || identity_username}
              </p>
            </Link>
          </div>
        </HoverTooltip>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)} className="h-full h-full">
          <div className="w-96">
            <SimilarUserCard
              similarUser={similarUser}
              profileUser={profileUser}
              referenceUserData={referenceUserData}
              similarUserData={similarUserData}
              fieldsOfInterest={fieldsOfInterest}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

const SimilarUsers = ({ limit, profileUser, similarUsersInput, className }) => {
  const [similarUsers, setSimilarUsers] = useState(similarUsersInput || []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (similarUsers.length === 0 && profileUser) {
        const simUsers = await UtilsLib.User.listSimilarUsers(
          profileUser,
          limit
        );
        setSimilarUsers(simUsers);
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !(similarUsers?.length > 0) || !profileUser) {
    return null;
  }

  const { username, identity_username: profileUserIdentityUsername } =
    profileUser || {};

  return (
    <div className={className}>
      <div className="bg-gray-100 p-2 sm:p-6 text-sm sm:text-base text-left border-b-2 overflow-hidden rounded-t-sm">
        <p className="text-[#27272A] font-bold">
          Like what you see in{" "}
          <span className="text-[#007A94]">
            {username || profileUserIdentityUsername}
          </span>
          ?!
        </p>
        <p className="text-gray-600">
          Find more similar freelancers in the Torc Community.
        </p>
      </div>
      <div className="flex justify-center items-center p-2 sm:p-6">
        <div className="flex flex-wrap p-2 sm:p-4 w-[85vw] sm:w-[375px]">
          {similarUsers.map((similarUser) => (
            <SimilarUser
              key={similarUser.id}
              similarUser={similarUser}
              profileUser={profileUser}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SimilarUsers.propTypes = {
  limit: PropTypes.number,
  profileUser: PropTypes.object,
};

SimilarUsers.defaultProps = {
  limit: 4,
  profileUser: {},
};

export default SimilarUsers;
