import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { createApplication } from "graphql/mutations";
import classNames from "classnames";
// import SearchField from "components/SearchField";
import RoleCard from "./RoleCard";

const Role = ({
  jobRoles,
  setJobRoles,
  roleRef,
  setDisabled,
  isCurrent,
  setAutoSaved,
  className,
  setReminderText,
}) => {
  const [jobSearchTerm /*setJobSearchTerm*/] = useState("");

  useEffect(() => {
    if (!isCurrent) return;
    const shouldDisable = !jobRoles?.some(({ isApplied }) => isApplied);
    setDisabled(shouldDisable);
    if (shouldDisable) {
      setReminderText("Select at least one");
    } else {
      setReminderText("");
    }
  }, [jobRoles, setDisabled, isCurrent, setReminderText]);

  const toggleIsActiveField = (jobTypeId) => {
    setJobRoles(
      jobRoles.map((jr) => {
        if (jobTypeId === jr.id) {
          jr.isApplied = !jr.isApplied;
        }
        return jr;
      })
    );
  };

  async function applyToJobRole(jobTypeId) {
    try {
      toggleIsActiveField(jobTypeId);
      await API.graphql(
        graphqlOperation(createApplication, { input: { jobTypeId } })
      );
    } catch (err) {
      toggleIsActiveField(jobTypeId);
      console.log("Error when submitting application to db");
      console.log(err);
    }
  }

  const filterSearch = (job) =>
    job.displayName?.toLowerCase()?.includes(jobSearchTerm);

  // const triggerJobSearch = (e) => setJobSearchTerm(e.target.value);
  return (
    <div
      ref={roleRef}
      className={classNames("flex flex-col gap-4 mt-4 pb-[150px]", className)}
    >
      <div className="flex items-baseline gap-4 text-xl">
        <div className="b2">Select the roles that best describe you.</div>
      </div>
      {/* hiding until we have more choices
      <SearchField
        containerClassName="max-h-11 md:max-h-[52px] py-2"
        value={jobSearchTerm}
        onSearchChange={triggerJobSearch}
        className="searchBar xs"
        placeholder="Search roles"
        disableLoading
      />
      */}
      <div className="flex flex-wrap justify-items-center gap-2 md:gap-3">
        {jobRoles
          .sort((a, b) => a.sort - b.sort)
          .filter((j) => j.logo && (jobSearchTerm ? filterSearch(j) : true))
          .map((jobRole, index) => (
            <RoleCard
              key={index}
              selected={jobRole.isApplied}
              logo={jobRole.logo}
              handleClick={async () => {
                await applyToJobRole(jobRole.id);
                setAutoSaved(true);
              }}
              displayName={jobRole.displayName}
            />
          ))}
      </div>
    </div>
  );
};

export default Role;
