import classNames from "classnames";
import { useMemo } from "react";

const RoleCard = ({ handleClick, selected, logo, displayName }) => {
  const logoSrc = useMemo(() => {
    let src;
    try {
      src = require(`images/imagery/${logo}`);
    } catch (e) {
      src = "";
    }
    return src;
  }, [logo]);

  return (
    <button
      onClick={handleClick}
      className={classNames(
        "flex items-center gap-[4px] py-[7px] px-3 rounded-sm border-2 hover:border-brandSecondary-1000",
        {
          "!opacity-100 text-brandSecondary-1000 border-brandSecondary-1000 font-bold":
            selected,
          "border-grey-300": !selected,
        }
      )}
    >
      <img
        className="w-[18px] h-[18px] md:w-6 md:h-6"
        src={logoSrc}
        alt={`${displayName} logo`}
        onError={(e) => (e.target.src = "")}
      />
      <p className="text-xs md:text-lg">{displayName}</p>
    </button>
  );
};

export default RoleCard;
